.hero-loading {
  height: 100vh;
  position: relative;
  color: rgb(53, 237, 59);
  background-image: url("../assets/error.gif");
}
.hero .hero-content {
  text-align: center;
  position: absolute;
}

.hero .overlay-itro {
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  left: 0px;
  padding: 0;
  height: 100%;
  width: 100%;
  opacity: 0.9;
}
.hero .hero-title {
  color: rgb(53, 237, 59);
  font-weight: 600;
  font-size: 3rem;
}

@media (min-width: 768px) {
  .hero .hero-title {
    font-size: 4.5rem;
  }
}

.hero .hero-subtitle {
  font-size: 1.5rem;
  font-weight: 600;
}

.bg-image {
  /* background-image: url('../assets/glitched_decentralized.gif'); */
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center center;
}

.display-table {
  width: 100%;
  height: 100%;
  display: table;
}

.table-cell {
  display: table-cell;
  vertical-align: middle;
}
.buyNFT {
  cursor: pointer;
  color: rgb(0, 255, 17);
  border-radius: 15px 15px;
  padding: 15px;
  border: 2px solid rgb(0, 255, 17);
  font-size: 2rem;
  font-weight: bold;
  margin-top: 80px;
}
.buyNFT h5 {
  font-size: 3rem;
  font-weight: 600;
}
.back-button {
  cursor: pointer;
  margin-top: 30px;
  margin-left: 30px;
}
